import { on_ready, is_elementor_edit, hover_intent, queue_tasks } from 'js/utils';
import Save_Items from 'classes/Save_Items';
import Toggle_Height from 'classes/Toggle_Height';
import retina from './vendors/retina/retina';
import header_init from 'modules/header/header';
import sticky_footer_cta_init from 'modules/footer/sticky_footer_cta/sticky_footer_cta';
import forms_init from 'modules/forms/forms';
import carousels_init from 'modules/carousel/carousel';

// load global styles
import './main.scss';

on_ready(()=>{

    if( is_elementor_edit() ) {
        // elementor edit screen: do not run scripts
        return;
    }
    
    queue_tasks([

        // sticky header
        header_init,

        // sticky footer
        sticky_footer_cta_init,

        // forms ui + gravityforms customizations
        forms_init,

        // swiper carousel
        carousels_init,

        // load @2x images
        init_retina,

        // add delay on hover out - to avoid instant close/off
        init_hover_intent,

        // toggle element height from 0 to default - for animating height
        init_toggle_height,

        // close toggles/dropdowns on mobile
        mobile_close_footer_dropdowns,

        //Tabs IOS Fix
        initTabScrollFix,
    ]);
    
    // add class to elements with scrollbar - for extra padding styling
    // setTimeout(()=>check_elements_scrollbar(), 100);
    
    // slide menu: remove if not used
    if( document.querySelector('.ff_slide_menu') ) {
        setTimeout(()=>import('modules/menu/slide_menu/slide_menu'),0);
    }

    // search popup: remove if not used
    if( document.querySelector('.search_popup') ) {
        setTimeout(()=>import('modules/search/search_popup/search_popup'),0);
    }

    // wishlist - remove if not used
    // new Save_Items({
    //     key: 'wishlist',
    //     total_count_element: '.wishlist_count',
    //     add_item_button: '.wishlist_add',
    // });

    // compare - remove if not used
    // new Save_Items({
    //     key: 'compare',
    //     total_count_element: '.compare_count',
    //     add_item_button: '.compare_add',
    //     limit: 3,
    //     limit_message: 'You can only add up to 3 items to compare',
    // });

});

function init_hover_intent(){
    document.querySelectorAll('.hover_intent').forEach(el=>{
        hover_intent(el);
    });
}

// function check_elements_scrollbar(){
//     document.querySelectorAll('.check_scrollbar').forEach(element=>{
//         if( element.scrollHeight > element.clientHeight ) {
//             element.classList.add('with_scrollbar');
//         }
//     })
// }

function init_retina(){
    
    if( typeof retina === 'undefined' ) return;
    if( window.devicePixelRatio < 2) return;

    let items = document.querySelectorAll('.retina');
    if( !items ) return;
    
    items.forEach(item=>{
        if( item.nodeName == 'IMG' ) {
            item.dataset.rjs = 2;
        } else {
            // add to children
            item.querySelectorAll('img').forEach(img=>{
                img.dataset.rjs = 2;
            })
        }
    })
    
    retina();
}

function init_toggle_height(){
    document.querySelectorAll('.ff_toggle_height').forEach(container=>{
        
        if( typeof container.ff_toggle_height !== 'undefined' ) return;

        let trigger = container.querySelector('.ff_toggle_height_trigger');
        let target = container.querySelector('.ff_toggle_height_target');
        if( !trigger || !target ) return;
        
        container.ff_toggle_height = new Toggle_Height({
            trigger,
            target,
        });

    })
}

// close dropdowns in footer during load on mobile
function mobile_close_footer_dropdowns(){
    if( window.innerWidth > 768 ) return;
    document.querySelectorAll('.elementor-location-footer .ff_toggle_height_trigger.open').forEach(dropdown=>{
        dropdown.click();
    })
}

// Tabs iOS fix 
function initTabScrollFix() {
    if (window.innerWidth > 768) return;

    function scrollToTabContainer(tabContainer) {
        const tabContainerTop = tabContainer.getBoundingClientRect().top + window.pageYOffset - 90;
        window.scrollTo(0, tabContainerTop);
    }
    document.querySelectorAll('.e-n-tabs').forEach(tabContainer => {
        tabContainer.querySelectorAll('.e-n-tab-title').forEach(tab => {
            tab.addEventListener('click', () => {
                setTimeout(() => scrollToTabContainer(tabContainer), 100);
            });
        });
    });
}